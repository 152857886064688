//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconAppStoreUk from '~/assets/img/apps/app-store-uk.svg?inline';
import IconAppStoreEn from '~/assets/img/apps/app-store-en.svg?inline';
import IconGooglePlayUk from '~/assets/img/apps/google-play-uk.svg?inline';
import IconGooglePlayEn from '~/assets/img/apps/google-play-en.svg?inline';

export default {
  name: 'BaseLinkApp',
  components: {
    'app-store-uk': IconAppStoreUk,
    'app-store-en': IconAppStoreEn,
    'google-play-uk': IconGooglePlayUk,
    'google-play-en': IconGooglePlayEn,
  },
  props: {
    link: {
      type: Object,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
  computed: {
    getIcon() {
      const icon = this.icon;
      const locale = this.$i18n.locale;

      return icon && `${icon}-${locale}`;
    },
  },
}
